header {
  background-color: #fff; /* White background */
  padding: 20px 50px;
  text-align: center;
}

header h1 {
  margin: 0;
  color: #000; /* Black color for the text */
  font-size: 48px; /* Larger font size for the main title */
  font-family: "Quicksand", sans-serif; /* Using Quicksand for the title */
  font-weight: 300; /* Light weight */
}

header p {
  color: #666; /* Dark gray for the subheading */
  font-size: 16px; /* Smaller font size for the subheading */
  margin: 4px 0;
  font-family: "Quicksand", sans-serif; /* Consistency in font */
  font-weight: 300; /* Light weight */
}

nav {
  margin-top: 20px;
  display: flex;
  justify-content: center; /* Center the navigation links */
}

nav a {
  margin: 0 20px;
  color: #666; /* Gray color for the links */
  text-decoration: none;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  text-transform: capitalize; /* Capitalize each word */
  font-weight: 300; /* Light weight for links */
}

nav a:hover {
  color: #000; /* Black color for links on hover */
  text-decoration: none; /* No underline on hover */
  font-weight: 400;
}

nav a.active {
  color: #000; /* Black color for the active link */
  font-weight: 600; /* Make the active link bolder */
}
