.viewer {
  position: relative;
  width: 100%;
  padding-top: 35%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.image-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease; /* Crossfade transition */
}

.image-slide.active {
  opacity: 1;
}

/* Various animations for one-way transitions */
@keyframes pan1 {
  from {
    transform: translate(0%, 0%) scale(1);
  }
  to {
    transform: translate(10%, 10%) scale(1.1);
  }
}

@keyframes pan2 {
  from {
    transform: translate(0%, 0%) scale(1);
  }
  to {
    transform: translate(0%, 10%) scale(1.1);
  }
}

@keyframes pan3 {
  from {
    transform: translate(0%, 0%) scale(1);
  }
  to {
    transform: translate(-10%, 0%) scale(1.1);
  }
}

@keyframes pan4 {
  from {
    transform: translate(0%, 0%) scale(1);
  }
  to {
    transform: translate(0%, -10%) scale(1.1);
  }
}

@keyframes pan5 {
  from {
    transform: translate(0%, 0%) scale(1);
  }
  to {
    transform: translate(-10%, 10%) scale(1.1);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

/* Apply animations to classes */
.animate-1 {
  animation: pan1 10s linear 1 forwards;
}

.animate-2 {
  animation: pan2 10s linear 1 forwards;
}

.animate-3 {
  animation: pan3 10s linear 1 forwards;
}

.animate-4 {
  animation: pan4 10s linear 1 forwards;
}

.animate-5 {
  animation: pan5 10s linear 1 forwards;
}

.animate-6 {
  animation: zoomIn 10s linear 1 forwards;
}

.animate-7 {
  animation: zoomOut 10s linear 1 forwards;
}
