.about-contact-container {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 0;
  padding: 0;
  width: 250mm;
  height: 279mm;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative; /* Set positioning context for absolute elements inside */
}

.left-column {
  position: relative; /* Crucial for positioning the button absolutely within */
  padding: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: black;
}

.download-pdf-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #f0f0f0;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.right-column {
  padding: 20px;
  background-color: #003366;
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.right-column img {
  width: 70%;
  height: auto;
  border-radius: 5%; /* Updated to make the image oval */
  margin-bottom: 20px;
  display: block;

  margin-right: auto;
}

h1 {
  margin: 10px 0 5px;
}

h3 {
  margin: 20px 0 5px; /* This margin applies to all h3 tags in both columns */
}

.right-column h3 {
  margin-bottom: 2px; /* Reduced bottom margin for right column h3 tags to reduce gap */
}

ul {
  list-style-type: none;
  padding: 0;
  color: inherit;
  font-size: 0.8rem;
  margin-bottom: 10px; /* Consistent bottom margin for lists */
}

li {
  margin-bottom: 5px; /* Added margin to increase space between list items */
}

p {
  font-size: 0.8rem; /* Consistent smaller font size for paragraphs */
}
